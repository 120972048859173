import { render, staticRenderFns } from "./StartsWith.vue?vue&type=template&id=4d98e802&scoped=true&"
import script from "./StartsWith.vue?vue&type=script&lang=ts&"
export * from "./StartsWith.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4d98e802",
  null
  
)

export default component.exports