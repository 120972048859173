







import BaseStringList from './components/BaseStringList.vue'
import InvertCondition from './components/InvertCondition.vue'
import { StartsWithCondition } from '@/includes/types/PmConfig.types'

import { Component, VModel } from 'vue-property-decorator'
import Vue from 'vue'

@Component({
  components: { BaseStringList, InvertCondition }
})
export default class StartsWith extends Vue {
  @VModel() model!: StartsWithCondition
}
